<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <h4 class="text-primary">
          NHÓM NGƯỜI DÙNG
        </h4>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="text-nowrap"
          @click="goCreateRole"
        >
          <feather-icon
            class="text-white"
            icon="PlusIcon"
          />
          Nhóm mới
        </b-button>
      </b-card-header>
      <b-table
        ref="refUsersListTable"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="roles"
        :fields="tableColumns"
      >

        <!-- Column: Nhóm người dùng -->
        <template #cell(name)="data">
          <b-media
            vertical-align="center"
            class="align-items-stretch"
          >
            <div class="d-flex flex-column justify-content-between h-100">
              <div
                class="cursor-pointer font-weight-bold text-primary text-nowrap"
                @click="goRoleDetail(data.item)"
              >{{ data.item.name }}
              </div>
              <small>{{ data.item.description }}</small>
            </div>
          </b-media>
        </template>

        <template #cell(code)="data">
          <span class="text-primary font-weight-bolder">{{ data.item.code }}</span>
        </template>

        <!-- Column: Hành động -->
        <template #cell(actions)="data">
          <b-button
            :disabled="data.item.code.toLowerCase() === 'admin' || data.item.code.toLowerCase() === 'pkh'"
            size="sm"
            variant="outline-danger"
            @click="openModalConfirmDeleteUser(data.item)"
          >Xóa</b-button>
        </template>
      </b-table>
    </b-card>

    <b-modal
      ref="modal-confirm-delete-role"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa Nhóm người dùng!"
      @ok="deleteRole(currentRole)"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> Nhóm người dùng
        <span class="text-primary font-weight-bolder">{{ currentRole.code }} (Mã: {{ currentRole.name }})</span>?
        <br>
        Việc <span class="text-danger">xóa</span> Nhóm người dùng sẽ ảnh hưởng tới các người dùng thuộc nhóm này!
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardText, BMedia, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import roleStoreModule from '@/views/settings/roles/roleStoreModule'
import useRolesList from './useRolesList'

export default {
  components: {
    BCardHeader,
    BCardText,
    BCard,
    BTable,
    BMedia,
    BButton,
  },
  directives: {
    Ripple,
  },
  created() {
    this.$action(this.fetchRoles())
  },
  methods: {
    goCreateRole() {
      this.$router.push({ name: 'settings-roles-create' })
    },
    goRoleDetail(roleData) {
      this.$router.push({ name: 'settings-roles-edit', params: { id: roleData.id } })
    },
    openModalConfirmDeleteUser(u) {
      this.currentRole = u
      this.$refs['modal-confirm-delete-role'].show()
    },
  },
  setup() {
    const ROLE_STORE_MODULE_NAME = 'role'
    if (!store.hasModule(ROLE_STORE_MODULE_NAME)) store.registerModule(ROLE_STORE_MODULE_NAME, roleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(ROLE_STORE_MODULE_NAME)) store.unregisterModule(ROLE_STORE_MODULE_NAME)
    })

    return {
      currentRole: ref({}),
      ...useRolesList(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
